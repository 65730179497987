import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Component, Input, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-inputgroup,
.p-inputgroup .p-floatlabel,
.p-inputgroup .p-iftalabel {
    display: flex;
    align-items: stretch;
    width: 100%;
}

.p-inputgroup .p-inputtext,
.p-inputgroup .p-inputwrapper {
    flex: 1 1 auto;
    width: 1%;
}

.p-inputgroupaddon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${dt('inputgroup.addon.padding')};
    background: ${dt('inputgroup.addon.background')};
    color: ${dt('inputgroup.addon.color')};
    border-block-start: 1px solid ${dt('inputgroup.addon.border.color')};
    border-block-end: 1px solid ${dt('inputgroup.addon.border.color')};
    min-width: ${dt('inputgroup.addon.min.width')};
}

.p-inputgroupaddon:first-child,
.p-inputgroupaddon + .p-inputgroupaddon {
    border-inline-start: 1px solid ${dt('inputgroup.addon.border.color')};
}

.p-inputgroupaddon:last-child {
    border-inline-end: 1px solid ${dt('inputgroup.addon.border.color')};
}

.p-inputgroupaddon:has(.p-button) {
    padding: 0;
    overflow: hidden;
}

.p-inputgroupaddon .p-button {
    border-radius: 0;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-component,
.p-inputgroup:first-child > p-button > .p-button,
.p-inputgroup > .p-floatlabel > .p-component,
.p-inputgroup > .p-floatlabel > .p-inputwrapper > .p-component,
.p-inputgroup > .p-iftalabel > .p-component,
.p-inputgroup > .p-iftalabel > .p-inputwrapper > .p-component {
    border-radius: 0;
    margin: 0;
}

.p-inputgroupaddon:first-child,
.p-inputgroup > .p-component:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-floatlabel:first-child > .p-component,
.p-inputgroup > .p-floatlabel:first-child > .p-inputwrapper > .p-component,
.p-inputgroup > .p-iftalabel:first-child > .p-component,
.p-inputgroup > .p-iftalabel:first-child > .p-inputwrapper > .p-component {
    border-start-start-radius: ${dt('inputgroup.addon.border.radius')};
    border-end-start-radius: ${dt('inputgroup.addon.border.radius')};
}

.p-inputgroupaddon:last-child,
.p-inputgroup > .p-component:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-floatlabel:last-child > .p-component,
.p-inputgroup > .p-floatlabel:last-child > .p-inputwrapper > .p-component,
.p-inputgroup > .p-iftalabel:last-child > .p-component,
.p-inputgroup > .p-iftalabel:last-child > .p-inputwrapper > .p-component {
    border-start-end-radius: ${dt('inputgroup.addon.border.radius')};
    border-end-end-radius: ${dt('inputgroup.addon.border.radius')};
}

.p-inputgroup .p-component:focus,
.p-inputgroup .p-component.p-focus,
.p-inputgroup .p-inputwrapper-focus,
.p-inputgroup .p-component:focus ~ label,
.p-inputgroup .p-component.p-focus ~ label,
.p-inputgroup .p-inputwrapper-focus ~ label {
    z-index: 1;
}

.p-inputgroup > .p-button:not(.p-button-icon-only) {
    width: auto;
}

/*For PrimeNG*/

.p-inputgroup p-button:first-child, .p-inputgroup p-button:last-child {
    display: inline-flex;
}

.p-inputgroup:has(> p-button:first-child) .p-button{
    border-start-start-radius: ${dt('inputgroup.addon.border.radius')};
    border-end-start-radius: ${dt('inputgroup.addon.border.radius')};
}

.p-inputgroup:has(> p-button:last-child) .p-button {
    border-start-end-radius: ${dt('inputgroup.addon.border.radius')};
    border-end-end-radius: ${dt('inputgroup.addon.border.radius')};
}
`;
const classes = {
  root: ({
    props
  }) => ['p-inputgroup', {
    'p-inputgroup-fluid': props.fluid
  }]
};
class InputGroupStyle extends BaseStyle {
  name = 'inputgroup';
  theme = theme;
  classes = classes;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵInputGroupStyle_BaseFactory;
    return function InputGroupStyle_Factory(__ngFactoryType__) {
      return (ɵInputGroupStyle_BaseFactory || (ɵInputGroupStyle_BaseFactory = i0.ɵɵgetInheritedFactory(InputGroupStyle)))(__ngFactoryType__ || InputGroupStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: InputGroupStyle,
    factory: InputGroupStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputGroupStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * InputGroup displays text, icon, buttons and other content can be grouped next to an input.
 *
 * [Live Demo](https://www.primeng.org/inputgroup/)
 *
 * @module inputgroupstyle
 *
 */
var InputGroupClasses;
(function (InputGroupClasses) {
  /**
   * Class name of the root element
   */
  InputGroupClasses["root"] = "p-inputgroup";
})(InputGroupClasses || (InputGroupClasses = {}));
const _c0 = ["*"];
/**
 * InputGroup displays text, icon, buttons and other content can be grouped next to an input.
 * @group Components
 */
class InputGroup extends BaseComponent {
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  _componentStyle = inject(InputGroupStyle);
  static ɵfac = /*@__PURE__*/(() => {
    let ɵInputGroup_BaseFactory;
    return function InputGroup_Factory(__ngFactoryType__) {
      return (ɵInputGroup_BaseFactory || (ɵInputGroup_BaseFactory = i0.ɵɵgetInheritedFactory(InputGroup)))(__ngFactoryType__ || InputGroup);
    };
  })();
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: InputGroup,
    selectors: [["p-inputgroup"], ["p-inputGroup"], ["p-input-group"]],
    hostAttrs: [1, "p-inputgroup"],
    hostVars: 5,
    hostBindings: function InputGroup_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵattribute("data-pc-name", "inputgroup");
        i0.ɵɵstyleMap(ctx.style);
        i0.ɵɵclassMap(ctx.styleClass);
      }
    },
    inputs: {
      style: "style",
      styleClass: "styleClass"
    },
    features: [i0.ɵɵProvidersFeature([InputGroupStyle]), i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function InputGroup_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    dependencies: [CommonModule, SharedModule],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputGroup, [{
    type: Component,
    args: [{
      selector: 'p-inputgroup, p-inputGroup, p-input-group',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: ` <ng-content></ng-content> `,
      providers: [InputGroupStyle],
      host: {
        class: 'p-inputgroup',
        '[attr.data-pc-name]': '"inputgroup"',
        '[class]': 'styleClass',
        '[style]': 'style'
      }
    }]
  }], null, {
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(InputGroup, {
    className: "InputGroup",
    filePath: "inputgroup.ts",
    lineNumber: 24
  });
})();
class InputGroupModule {
  static ɵfac = function InputGroupModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || InputGroupModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: InputGroupModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [InputGroup, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputGroupModule, [{
    type: NgModule,
    args: [{
      imports: [InputGroup, SharedModule],
      exports: [InputGroup, SharedModule]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(InputGroupModule, {
    imports: [InputGroup, SharedModule],
    exports: [InputGroup, SharedModule]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { InputGroup, InputGroupClasses, InputGroupModule, InputGroupStyle };
